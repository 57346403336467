// Example url: https://app.withgrayce.com/path/to/content
// Example deep link: com.withgrayce.app://path/to/content

export const getPathFromDeepLinkOrStandardUrl = (stringUrl: string) => {
  const deepLinkScheme = "com.withgrayce.app://";
  if (stringUrl.startsWith(deepLinkScheme)) {
    const path = stringUrl.replace(deepLinkScheme, "/");
    return path;
  }

  const url = new URL(stringUrl);
  if (url.host === "app.withgrayce.com") {
    const pathWithQuery = url.pathname + url.search;
    if (pathWithQuery) {
      return pathWithQuery;
    }
  }
};
